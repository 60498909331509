import {NgModule} from '@angular/core';
import {InputFormatterDirective} from './input-formatter.directive';
import {DragDropDirective} from './drag-drop.directive';
import {InputOnClickDirective} from './input-on-click.directive';
import {LoadingDirective} from './loading.directive';
import {RangeValidatorDirective} from './range-validator.directive';
import {StaticNumericFormatterDirective} from './static-numeric-formatter.directive';
import {TableColumnDirective} from './table-column.directive';
import {DifferenceHightlightDirective} from './difference-hightlight.directive';
import {HoverModifierDirective} from '@/app/directives/hover-modifier.directive';
import {PositionPopupDirective} from './position-popup.directive';
import {MaximumInvestmentValidatorDirective} from '@/app/directives/maximum-investment-validator.directive';
import {OppieLoadingModule} from '@/app/components/oppie-loading/oppie-loading.module';

@NgModule({
    declarations: [
        InputFormatterDirective,
        DragDropDirective,
        InputOnClickDirective,
        LoadingDirective,
        RangeValidatorDirective,
        StaticNumericFormatterDirective,
        TableColumnDirective,
        DifferenceHightlightDirective,
        HoverModifierDirective,
        PositionPopupDirective,
        MaximumInvestmentValidatorDirective,
    ],
    imports: [
        OppieLoadingModule
    ],
    exports: [
        InputFormatterDirective,
        DragDropDirective,
        InputOnClickDirective,
        LoadingDirective,
        RangeValidatorDirective,
        StaticNumericFormatterDirective,
        TableColumnDirective,
        DifferenceHightlightDirective,
        HoverModifierDirective,
        PositionPopupDirective,
        MaximumInvestmentValidatorDirective
    ],
})
export class DirectivesModule {
}
